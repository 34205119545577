import React, {useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import {OfferCompoundService, OfferSiteService, TourOverview} from "../../services/restserver-openapi";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import { formatAsSwissDate } from '../../utils/dateUtil';
import { useNavigate } from 'react-router-dom';
import imgSlider1 from "../../assets/Images/trip_planner.jpg";
import Container from "../Container";

interface BookedTourCardProp {
    tour: TourOverview;
}

function BookedTourCard({ tour }: BookedTourCardProp) {
    const navigate = useNavigate();

    const { earliestDate, latestDate } = getTourDateRange(tour);

    const [rightImage, setRightImage] = useState(imgSlider1.toString());

    useEffect(() => {
        const fetchImages = async () => {
            const siteDetails = await OfferSiteService.getSite(tour.stays![0].siteId!);
            const compoundDetails = await OfferCompoundService.getCompound(siteDetails.compoundId!);
            if (compoundDetails.regions && compoundDetails.regions[0].imageIds && compoundDetails.regions[0].imageIds.length > 0) {
                setRightImage(`${process.env.REACT_APP_API_URL as string}/image/${compoundDetails.regions[0].imageIds[0]}`);
            }
        };
        fetchImages();
    }, [tour]);


    let bookedAt: string;
    if (tour.statusDate) {
        bookedAt = formatAsSwissDate(tour.statusDate);
    } else {
        bookedAt = 'Kein Datum';
    }

    function getTourDateRange(tour: TourOverview): {
        earliestDate: number | undefined;
        latestDate: number | undefined;
    } {
        let earliestDate: number | undefined;
        let latestDate: number | undefined;

        if (tour.stays && tour.stays.length > 0) {
            for (const stay of tour.stays) {
                if (stay.fromDate !== undefined) {
                    if (earliestDate === undefined || stay.fromDate < earliestDate) {
                        earliestDate = stay.fromDate;
                    }
                }

                if (stay.toDate !== undefined) {
                    if (latestDate === undefined || stay.toDate > latestDate) {
                        latestDate = stay.toDate;
                    }
                }
            }
        }

        return { earliestDate, latestDate };
    }

    const RightSide = () => {
        return (
            <Box
                sx={{
                    height: '100%',
                    '& .slick-slide img': {
                        objectFit: 'cover',
                    },
                    '& .slick-list, & .slick-slider, & .slick-track, & .slick-slide > div': {
                        height: '100%',
                    },
                }}
            >
                <Box
                    component={'img'}
                    loading="lazy"
                    src={rightImage}
                    height={'100%'}
                    style={{ objectFit: 'cover' }}
                    width={1}
                />
            </Box>
        );
    };

    const LeftSide = () => {
        return (
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <SearchIcon sx={{ mr: '6px' }} /><Typography variant="h6">{tour.name}</Typography>
                </Box>
                <Typography variant="body1" color="text.secondary">
                    {formatAsSwissDate(earliestDate!)} - {formatAsSwissDate(latestDate!)}
                </Typography>
                <Box sx={{ mb: 1.5 }} />
                <Typography variant="body2">
                    Gebucht am {bookedAt}
                </Typography>
                <Typography variant="body2">
                    Preis: {tour.price?.toFixed(2)} CHF
                </Typography>
                <Typography variant="body2">
                    Tour ID: {tour.tourId}
                </Typography>
            </CardContent>
        );
    };

    return (
        <Card sx={{ cursor: 'pointer' }} elevation={2} onClick={() => navigate(`/vanlifer/Tour/${tour.tourId}`)}>
            <Box
                display={'flex'}
                flexDirection={{ xs: 'row' }}
            >
                <Box
                    width={1}
                    order={{ xs: 1 }}
                    display={'flex'}
                    alignItems={'center'}
                >
                    <LeftSide />
                </Box>
                <Box
                    sx={{
                        flex: { xs: '0 0 44%' },
                        position: 'relative',
                        maxWidth: { xs: '44%' },
                        order: { xs: 2 },
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%' },
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    overflow: 'hidden',
                                    left: '0%',
                                    width: 1,
                                    height: 1,
                                    position: { xs: 'absolute' },
                                    clipPath: {
                                        xs: 'polygon(10% 0%, 100% 0, 100% 100%, 10% 100%, 0% 50%)',
                                    },
                                    shapeOutside: {
                                        xs: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%, 0% 50%)',
                                    },
                                }}
                            >
                                <RightSide />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}

export default BookedTourCard;
