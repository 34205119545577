/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Offer } from './Offer';
import type { Property } from './Property';
export type Site = {
    siteId?: number;
    offer?: Offer;
    compoundId?: number;
    sizeId?: number;
    label?: string;
    siteType?: Site.siteType;
    properties?: Array<Property>;
    guestInformation?: string;
    imageIds?: Array<number>;
    number?: string;
    name?: string;
    area?: number;
    sizeFk?: number;
    minimumOccupancy?: number;
    maximumOccupancy?: number;
    petsAllowed?: boolean;
    nonSmoking?: boolean;
    tvConnection?: boolean;
    cookingFacility?: boolean;
    minibar?: boolean;
    sharedBathroom?: boolean;
    sharedShower?: boolean;
    privateBathroom?: boolean;
    privateShower?: boolean;
    refrigerator?: boolean;
    balconyOrTerrace?: boolean;
    electricity?: boolean;
    water?: boolean;
    wifi?: boolean;
};
export namespace Site {
    export enum siteType {
        ROOM = 'ROOM',
        APARTMENT = 'APARTMENT',
        PITCH = 'PITCH',
        MOBILEHOME = 'MOBILEHOME',
        SITEOTHER = 'SITEOTHER',
    }
}

