import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import {OfferReviewService, RatingAndComment, User} from "../../../services/restserver-openapi";
import {StayDetail, Compound} from "../../../services/restserver-openapi";
import {asyncCheckKudos} from "../../../store/Vanlifer/Gamification/KudosSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Stack from '@mui/material/Stack';
import Tooltip from "@mui/material/Tooltip";
import TextField from '@mui/material/TextField';
import {putUserAsync} from "../../../store/navBar/userSlice"; // Import the putUserAsync function

interface ReviewModalProps {
    stay?: StayDetail;
    compound?: Compound;
    currentUserReview: RatingAndComment | null;
    setCurrentUserReview: (review: RatingAndComment | null) => void;
    reviewType: 'stay' | 'compound';
}

export function ReviewModal({stay, compound, currentUserReview, setCurrentUserReview, reviewType}: ReviewModalProps): React.ReactElement {
    const dispatch = useAppDispatch();
    const userId = useAppSelector(state => state.login.userId);

    const kudosLastCheckedAt = useAppSelector(state => state.kudos.kudosLastCheckedAt);
    const userCommunityName = useAppSelector(state => state.user.user.communityName);

    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState<number | null>(null);
    const [comment, setComment] = useState<string>("");

    const userEntitlements = useAppSelector(state => state.user.entitlements);
    const canWriteReviews = useMemo(() => {
        return userEntitlements.some(entitlement => entitlement.featureId === 'vanliferwritereview' && entitlement.value === "true")
    }, [userEntitlements]);

    useEffect(() => {
        setRating(currentUserReview?.rating ?? null);
        setComment(currentUserReview?.comment ?? '');
    }, [currentUserReview]);

    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    const saveRating = async () => {
        if (rating !== null) {
            if (reviewType === 'stay' && stay) {
                await OfferReviewService.reviewSite(stay.staySite?.siteId!, {rating, comment});
            } else if (reviewType === 'compound' && compound) {
                await OfferReviewService.reviewCompound(compound.compoundId!, {rating, comment});
            }
            setCurrentUserReview({rating, comment});

            if (userId) {
                dispatch(asyncCheckKudos({checkKudosSinceDate: kudosLastCheckedAt}));
            }
        }

        closeModal();
    }

    const cancelRating = () => {
        setRating(currentUserReview?.rating ?? null);
        setComment(currentUserReview?.comment ?? '');
        closeModal();
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        minWidth: '250px',
        maxWidth: '100%',
        boxShadow: 24,
        p: 4,
    };

    const updateRating = (newValue: number | null) => {
        if (newValue !== null) {
            setRating(newValue);
        }
    }

    const updateReview = (newValue: string) => setComment(newValue);

    return (
        <>
            <Tooltip title={!canWriteReviews ? "Sie haben keine Berechtigung, eine Bewertung zu schreiben." : ""}>
                <span>
                    <Button
                        variant="contained"
                        onClick={openModal}
                        disabled={!canWriteReviews}
                    >
                        Bewerten
                    </Button>
                </span>
            </Tooltip>
            <Modal
                open={open}
                onClose={closeModal}
                sx={{m: 4}}
            >
                <Paper sx={style}>
                    <Typography variant="h6">
                        Bewertung für {reviewType === 'stay' ? stay?.stayCompound?.name : compound?.name} ({reviewType === 'stay' ? stay?.staySite?.label : ''})
                    </Typography>

                    <Stack spacing={2} sx={{mt: 2, mb: 3}}>
                        <Rating
                            value={rating}
                            precision={0.5}
                            onChange={(event, newValue) => updateRating(newValue)}
                        />

                        <TextareaAutosize minRows={4}
                                          placeholder="Wie war dein Aufenthalt? (optional)"
                                          value={comment}
                                          onChange={e => updateReview(e.target.value)}/>
                    </Stack>

                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                        <Button variant="outlined" onClick={cancelRating}>Abbrechen</Button>
                        <Button variant="contained" onClick={saveRating} disabled={!rating}>Speichern</Button>
                    </Box>
                </Paper>
            </Modal>
        </>
    );
}