import * as React from 'react';
import {useEffect} from 'react';
import {Compound, OfferReviewService, RatingAndComment, Review} from "../../services/restserver-openapi";
import Card from "@mui/material/Card";
import {Box, Grid, Skeleton, Typography} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import {formatAsSwissDate} from "../../utils/dateUtil";
import RateReviewIcon from '@mui/icons-material/RateReview';
import {TitleWithIcon} from "./TitleWithIcon";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import {ReviewModal} from "../vanliferComponents/Rating/ReviewModal";

interface CompoundReviewsProps {
    compoundId: number | undefined;
    compound: Compound
    setAverageRating: (rating: number | null) => void;
}

export function CompoundReviews({compoundId, compound, setAverageRating}: CompoundReviewsProps): React.ReactElement {
    const [siteReviews, setSiteReviews] = React.useState<Review[] | null>(null);
    const [compoundReviews, setCompoundReviews] = React.useState<Review[] | null>(null);
    const [error, setError] = React.useState<boolean>(false);
    const numberOfReviewsShown = 20;
    const [userReview, setUserReview] = React.useState<RatingAndComment | null>(null);

    const calculateAverageRating = (reviews: Review[]): number | null => {
        if (reviews.length === 0) {
            return null;
        }

        const totalRating = reviews.reduce((sum, review) => sum + review.rating!, 0);
        return totalRating / reviews.length;
    };

    const fetchReviews = () => {
        if (compoundId === undefined) {
            return;
        }

        setError(false);

        OfferReviewService.getCompoundReviews(compoundId)
            .then(reviews => {
                OfferReviewService.getOnlyCompoundReviews(compoundId)
                    .then(compoundReviews => {
                        const combinedReviews = [...reviews, ...compoundReviews];
                        setSiteReviews(reviews);
                        setCompoundReviews(compoundReviews);
                        setAverageRating(calculateAverageRating(combinedReviews));
                    });
            })
            .catch(e => setError(true));

        OfferReviewService.getUserCompoundReview(compoundId).then((review) => setUserReview(review));
    };

    useEffect(() => {
        fetchReviews();
    }, [setAverageRating, compoundId]);

    const handleSetUserReview = (review: RatingAndComment | null) => {
        setUserReview(review);
        fetchReviews();
    };

    if (error) {
        return <Typography>Es ist ein Fehler aufgetreten</Typography>;
    }

    const renderReviewCards = (reviews: Review[] | null, title: string): React.ReactNode => {
        if (reviews === null) {
            return (Array.from({length: 3}).map((_, index) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                    <Card>
                        <Skeleton variant="rounded" height={120} width="100%"/>
                    </Card>
                </Grid>
            )));
        } else if (reviews.length === 0) {
            return (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ mt: 2 }}>{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Es gibt leider noch keine Bewertungen...</Typography>
                    </Grid>
                </>
            );
        } else {
            return (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ mt: 2 }}>{title}</Typography>
                    </Grid>
                    {reviews.slice(0, numberOfReviewsShown).map((review, index) => (
                        <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="h6">{review.siteLabel ? `Angebot ${review.siteLabel}` : compound.name}</Typography>
                                        <Typography variant="subtitle1">({formatAsSwissDate(Number(review.timestamp))})</Typography>
                                    </Stack>

                                    <Rating value={review.rating} precision={0.5} readOnly/>
                                    <Typography>{review.comment}</Typography>
                                    <Typography variant="subtitle2">
                                        {review.vanliferName ? review.vanliferName : "Annonym"}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </>
            );
        }
    };

    return (
        <Box>
            <ReviewModal compound={compound}
                         currentUserReview={userReview}
                         setCurrentUserReview={handleSetUserReview}
                         reviewType={"compound"}
            />
            <Grid container sx={{my: 2}} spacing={2}>
                {renderReviewCards(compoundReviews, "Betriebsweite Bewertung")}
                {renderReviewCards(siteReviews, "Bewertungen einzelne Angebote")}
            </Grid>
        </Box>
    );
}