import React, {useEffect, useMemo, useRef} from 'react';
import {Box, Card, CardContent, CardMedia, Grid, Link, Typography, useTheme} from "@mui/material";
import Button from '@mui/material/Button';
import SVGComponent from '../presentationalComponents/SVGComponent';
import {StayDetail} from "../../services/restserver-openapi";
import moment from "moment";
import {EditCalendar} from '@mui/icons-material';
import {TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator} from "@mui/lab";
import AlternativeSitesComponent from "./AlternativeSitesComponent";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import LoadingPage from "../presentationalComponents/LoadingPage";
import EditStayCalender from "./EditStayCalendar/EditStayCalender";
import {asyncChangeStayDuration} from "../../store/Vanlifer/tourSlice";
import VltAlertDialog from '../presentationalComponents/VltAlertDialog';
import {vltAlertDialog} from "../../constants/textConstant";
import {ReviewModal} from "./Rating/ReviewModal";
import {SiteRating} from "./Rating/SiteRating";
import {OfferReviewService, RatingAndComment} from "../../services/restserver-openapi";
import {EcoFriendlyIcon} from './EcoFriendlyIcon';
import { useNavigate } from 'react-router-dom';
import { CompoundEquipment } from '../campsiteDetailComponents/CompoundEquipment';
import ImageIcon from "@mui/icons-material/Image";
import {createGeneralStyling} from "../../theme/generalStyling";
import {getReadableSiteType} from "../../utils/typeUtils";

interface SingleSiteProposalCardProps {
    stay: StayDetail;
    tourDistance: number;
    disableEditing: boolean;
}

const SingleSiteProposalCard: React.FC<SingleSiteProposalCardProps> = ({stay, tourDistance, disableEditing}) => {
    const theme = useTheme();
    const generalStyling = createGeneralStyling(theme);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const tourId = useAppSelector(state => state.tour.choosedTourDetail.tourId);
    const isLoading = useAppSelector(state => state.tour.loading);
    const userEntitlements = useAppSelector(state => state.user.entitlements);
    const canUseEditor = useMemo(() => {
        return userEntitlements.some(entitlement => entitlement.featureId === 'vanliferuseeditor' && entitlement.value === "true")
    }, [userEntitlements]);

    const [showStayCalender, setShowStayCalender] = React.useState(false);
    const [isEditingCheckin, setIsEditingCheckin] = React.useState(false);
    const [isEditingCheckout, setIsEditingCheckout] = React.useState(false);
    const [isStartDateFix, setIsStartDateFix] = React.useState(true);
    const [isEndDateFix, setIsEndDateFix] = React.useState(true);
    const [minDate, setMinDate] = React.useState(stay.alternativeStayDuration?.earliestFromDate);
    const [maxDate, setMaxDate] = React.useState(stay.alternativeStayDuration?.latestFromDate);
    const componentRef = useRef<HTMLDivElement>(null);
    const buttonCheckinRef = useRef<HTMLButtonElement>(null);
    const buttonCheckoutRef = useRef<HTMLButtonElement>(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [newFromDate, setNewFromDate] = React.useState(0);
    const [newToDate, setNewToDate] = React.useState(0)
    const [userReview, setUserReview] = React.useState<RatingAndComment | null>(null);

    let price = stay.price ? stay.price.toFixed(2) + " CHF" : 'Keine Information';

    useEffect(() => {
        let siteId = stay.staySite?.siteId;
        if (!siteId) {
            return;
        }

        OfferReviewService.getUserSiteReview(siteId).then((review) => setUserReview(review));
    }, [stay]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (showStayCalender
                && componentRef.current && !componentRef.current.contains(event.target as Node)
                && buttonCheckinRef.current && !buttonCheckinRef.current.contains(event.target as Node)
                && buttonCheckoutRef.current && !buttonCheckoutRef.current.contains(event.target as Node)) {
                setShowStayCalender(false);
                setIsEditingCheckout(false);
                setIsEditingCheckin(false);
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showStayCalender]);

    const handleClickOnCheckinDate = () => {
        setMinDate(stay.alternativeStayDuration?.earliestFromDate);
        setMaxDate(stay.alternativeStayDuration?.latestFromDate);
        setIsEndDateFix(true);
        setIsStartDateFix(false);
        if (isEditingCheckin) {
            setIsEditingCheckin(false);
            setShowStayCalender(false);
        } else if (isEditingCheckout) {
            setIsEditingCheckout(false);
            setIsEditingCheckin(true);
        } else {
            setIsEditingCheckin(true);
            setShowStayCalender(true);
        }
    }
    const handleClickOnCheckoutDate = () => {
        setIsEndDateFix(false);
        setIsStartDateFix(true);
        setMinDate(stay.alternativeStayDuration?.earliestToDate);
        setMaxDate(stay.alternativeStayDuration?.latestToDate);
        if (isEditingCheckout) {
            setIsEditingCheckout(false);
            setShowStayCalender(false);
        } else if (isEditingCheckin) {
            setIsEditingCheckin(false);
            setIsEditingCheckout(true);
        } else {
            setIsEditingCheckout(true);
            setShowStayCalender(true);
        }
    }

    const saveDateChanges = (newFromDate: number, newToDate: number) => {
        let stayId = stay.stayId
        if (stayId && tourId) {
            dispatch(asyncChangeStayDuration({ tourId, stayId, newFromDate, newToDate }))
        }
        setIsEditingCheckin(false);
        setIsEditingCheckout(false);
        setShowStayCalender(false);
    }

    const handleEditedStayDates = (newFromDate: number, newToDate: number) => {
        setNewFromDate(newFromDate);
        setNewToDate(newToDate);
        if (newFromDate === newToDate) {
            setOpenDialog(true);
        } else {
            saveDateChanges(newFromDate, newToDate);
        }
    }

    function isStayAlreadyOver(){
        return moment(stay.toDate).isSameOrBefore(moment());
    }

    function CompoundImage() {
        if (stay.stayCompound?.imageIds !== undefined &&
            stay.stayCompound.imageIds !== null &&
            stay.stayCompound.imageIds.length > 0) {
            return (
                <Grid item xs={12} md={3} sx={generalStyling.tourProposalCard.image}>
                    <CardMedia
                        component="img"
                        src={`${process.env.REACT_APP_API_URL as string}/image/${stay.stayCompound.imageIds[0]}`}
                        alt="Vanlife"
                        sx={{height: '100%'}}
                    />
                </Grid>
            );
        }

        return (
            <Grid item xs={12} md={3} sx={generalStyling.tourProposalCard.missingImage}>
                <ImageIcon fontSize="large" color="disabled"/>
                <Typography component={"article"} variant="body1" sx={{textAlign: 'center'}}>Kein Bild
                    vorhanden.</Typography>
            </Grid>
        );
    }

    return (
        <div>
            {
                openDialog &&
                    <VltAlertDialog
                        open={openDialog}
                        content={vltAlertDialog.dialogTextForDeleteStayInTour}
                        handleClose={() => { setOpenDialog(false) }}
                        handleAgree={() => saveDateChanges(newFromDate, newToDate)}
                        textAgreeButton={"Ja, Fortfahren"}
                        textDisagreeButton={"Abbrechen"} />
            }
            {
                isLoading
                    ? <LoadingPage />
                    : <Card sx={{ width: '100%' }}>
                        <Grid container display="flex">
                            <CompoundImage />
                            <Grid item xs={12} md={9}>
                                <CardContent>
                                    <Grid container display={"flex"}>
                                        <Grid item xs={12}>
                                            {
                                                disableEditing || !canUseEditor
                                                    ? <Typography>Checkin: {moment(stay.fromDate).format('DD.MM.YYYY')},
                                                        Checkout: {moment(stay.toDate).format('DD.MM.YYYY')}</Typography>
                                                    : <div>
                                                        <Button ref={buttonCheckinRef} color="primary" sx={{marginRight: '5px'}}
                                                                variant={isEditingCheckin ? "contained" : "text"}
                                                                onClick={handleClickOnCheckinDate}>Checkin: {moment(stay.fromDate).format('DD.MM.YYYY')}<EditCalendar/>
                                                        </Button>
                                                        <Button
                                                            ref={buttonCheckoutRef}
                                                            color="primary"
                                                            variant={isEditingCheckout ? "contained" : "text"}
                                                            onClick={handleClickOnCheckoutDate}>
                                                            Checkout: {moment(stay.toDate).format('DD.MM.YYYY')}
                                                            <EditCalendar/>
                                                        </Button>
                                                    </div>
                                            }
                                            {
                                                showStayCalender &&
                                                <Grid item xs={12} display={"flex"}>
                                                    <Box ref={componentRef} style={{
                                                        position: 'absolute',
                                                        zIndex: 1,
                                                        backgroundColor: `${theme.palette.alternate.main}`,
                                                        borderColor: `${theme.palette.primary.main}`,
                                                        border: `2px solid ${theme.palette.primary.main}`,
                                                        borderRadius: 10,
                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)'
                                                    }}>
                                                        <EditStayCalender minDate={minDate} maxDate={maxDate}
                                                                          handleEditedStayDates={handleEditedStayDates}
                                                                          isEndDateFix={isEndDateFix}
                                                                          isStartDateFix={isStartDateFix}
                                                                          startDateTimestamp={stay.fromDate}
                                                                          endDateTimestamp={stay.toDate}/>
                                                    </Box>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <hr/>
                                    <Grid container display={"flex"}>
                                        <Grid item xs={12} md={6}>
                                            <Link sx={{cursor: 'pointer'}} onClick={() => {
                                                if (stay.stayCompound) {
                                                    navigate(`/vanlifer/Campsite/${stay.stayCompound.compoundId}`);
                                                }}}>
                                                <Typography variant='h6'>
                                                    {stay.stayCompound?.name}
                                                    {stay.stayCompound?.sustainable && <EcoFriendlyIcon sx={{ml: 1, position: 'relative', top: '6px'}} />}
                                                </Typography>
                                            </Link>
                                            <Typography
                                                variant='body2'>{stay.stayCompound?.coordinates!.x}, {stay.stayCompound?.coordinates!.y}</Typography>
                                            <Grid my={1}>
                                                <Typography variant='body2'> {stay.stayCompound?.addressLine1}</Typography>
                                                <Typography
                                                    variant='body2'> {stay.stayCompound?.zipCode} {stay.stayCompound?.city} </Typography>
                                                <Typography variant='body2'> {stay.stayCompound?.country} </Typography>
                                            </Grid>
                                            <Typography my={1}
                                                        variant='body2'>{getReadableSiteType(stay.staySite!)}: {stay.staySite?.label} (Grösse {stay.staySite?.sizeId})</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} display={"flex"} my={1}>
                                            <Typography variant='body2'>{stay.staySite?.offerDescription}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} display={"flex"} alignItems="center" spacing={2} my={1}>
                                            <Typography variant="h6" component="p">{price}</Typography>
                                            <Typography variant='body2'>(Muss vor Ort bezahlt werden)</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sx={{marginTop: '12px'}}>
                                        <CompoundEquipment compound={stay.stayCompound} site={stay.staySite}/>
                                    </Grid>
                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "space-between"}}>
                                        <SiteRating siteId={stay.staySite?.siteId!}
                                                    userRating={userReview?.rating ?? null}/>
                                        {
                                            disableEditing && isStayAlreadyOver() || !canUseEditor ?
                                                <ReviewModal stay={stay}
                                                             currentUserReview={userReview}
                                                             setCurrentUserReview={setUserReview}
                                                             reviewType={"stay"}
                                                /> : null
                                        }
                                    </Grid>
                                    {
                                        disableEditing || !canUseEditor
                                            ? null
                                            : <Grid item xs={12} style={{paddingTop: 4}}>
                                                <AlternativeSitesComponent alternativeSiteList={stay.alternativeSites}
                                                                           stayId={stay.stayId!}/>
                                            </Grid>
                                    }
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
            }
            <TimelineItem sx={{ display: 'flex', justifyContent: 'center' }} color="primary">
                <TimelineSeparator>
                    <TimelineConnector />
                    <Typography color={"primary"}>
                        <SVGComponent iconValue="camper"/>
                    </Typography>
                    <TimelineConnector  />
                </TimelineSeparator>
                <TimelineContent alignSelf="center">
                    {
                        tourDistance && <Typography variant="h6">{tourDistance} km</Typography>
                    }
                </TimelineContent>
            </TimelineItem>
        </div>
    );
}

export default SingleSiteProposalCard;