/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RatingAndComment } from '../models/RatingAndComment';
import type { Review } from '../models/Review';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OfferReviewService {
    /**
     * Add a review for a site
     * @param siteId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static reviewSite(
        siteId: number,
        requestBody: RatingAndComment,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sites/{siteId}/review',
            path: {
                'siteId': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add a review for a compound
     * @param compoundId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static reviewCompound(
        compoundId: number,
        requestBody: RatingAndComment,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sites/{compoundId}/review/compound',
            path: {
                'compoundId': compoundId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the review of a site by id for a user
     * @param siteId
     * @returns Review OK
     * @throws ApiError
     */
    public static getUserSiteReview(
        siteId: number,
    ): CancelablePromise<Review> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/{siteId}/userReview',
            path: {
                'siteId': siteId,
            },
        });
    }
    /**
     * Add a rating for a site
     * @param siteId
     * @returns number OK
     * @throws ApiError
     */
    public static getAverageSiteRating(
        siteId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/{siteId}/rating',
            path: {
                'siteId': siteId,
            },
        });
    }
    /**
     * Get the review of a compound by id for a user
     * @param compoundId
     * @returns Review OK
     * @throws ApiError
     */
    public static getUserCompoundReview(
        compoundId: number,
    ): CancelablePromise<Review> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compounds/{compoundId}/userReview',
            path: {
                'compoundId': compoundId,
            },
        });
    }
    /**
     * Get all reviews of a compound by id
     * @param compoundId
     * @returns Review OK
     * @throws ApiError
     */
    public static getCompoundReviews(
        compoundId: number,
    ): CancelablePromise<Array<Review>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compounds/{compoundId}/reviews',
            path: {
                'compoundId': compoundId,
            },
        });
    }
    /**
     * Get all reviews of a compound by id only for the compound and not the corresponding sites
     * @param compoundId
     * @returns Review OK
     * @throws ApiError
     */
    public static getOnlyCompoundReviews(
        compoundId: number,
    ): CancelablePromise<Array<Review>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compounds/{compoundId}/onlycompoundreviews',
            path: {
                'compoundId': compoundId,
            },
        });
    }
}
